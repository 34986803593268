<template>
  <div class="home">
    <img alt="welcome" src="../../assets/image/welcome.png"/>
    <div>你好，欢迎使用</div>

  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {};
  },
  methods: {},

  mounted() {
  },

  beforeDestroy() {
  },

}
</script>

<style scoped lang="scss">
.home {
  text-align: center;
  margin: 0 auto;
  font-size: 53px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #707070;
}
img {
  width: 638px;
  height: 638px;
}
</style>

